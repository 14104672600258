<template>
  <div class="content">
    <div class="info-head" v-if="!loadding">
      <div class="user">
        <img class="portrait" :src="member.portrait" />
        <div class="text">
          <p>
            <strong>
              <OpenData type="userName" :openid="member.realName" />
            </strong>
          </p>
          <p>{{ $t('enterprise.table.account', 1) }}：{{ member.account }}</p>
          <!-- 账号： -->
          <p>
            {{ $t('CM_Department') }}：
            <!-- 部门： -->
            <a-tooltip>
              <template #title>
                <template
                  v-for="(str, strIndex) in member.department.split('/')"
                  :key="strIndex"
                >
                  <OpenData type="departmentName" :openid="str" />
                  <span
                    v-if="strIndex + 1 < member.department.split('/').length"
                    >/</span
                  >
                </template>
              </template>
              <OpenData type="departmentName" :openid="member.departmentName" />
            </a-tooltip>
          </p>
        </div>
      </div>
      <div class="task">
        <div class="text">
          <p class="title">
            <strong>{{ infoData.taskName }}</strong>
          </p>
          <p>
            {{ $t('CM_Status') }}：
            <!-- 状态： -->
            <template v-if="infoData.complete == 0">{{ $t('Pub_Tab_NotStart') }}</template>
            <!-- 未开始 -->
            <template v-else-if="infoData.complete == 1">{{ $t('enterprise.learn_state', 0) }}</template>
            <!-- 完成 -->
            <template v-else-if="infoData.complete == 2">{{ $t('enterprise.learn_state', 1) }}</template>
            <!-- 进行中 -->
            <template v-else-if="infoData.complete == 3">{{ $t('enterprise.learn_state', 2) }}</template>
            <!-- 逾期未完成 -->
          </p>
        </div>
      </div>
      <div class="progress">
        <a-progress
          type="circle"
          :width="120"
          strokeColor="#50b400"
          status="normal"
          :percent="infoData.progress"
        >
          <template #format="percent">
            <span style="color: #50b400">{{ percent }}%</span>
          </template>
        </a-progress>
      </div>
    </div>
    <div class="info-body" v-if="!loadding">
      <div class="stage" v-for="stage in infoData.stages" :key="stage.stageId">
        <h3 class="stage-title">{{ stage.stageName }}</h3>
        <div class="item" v-for="(item, index) in stage.details" :key="index">
          <div class="left">
            <span>{{ item.detailName }}</span>
            <img
              class="icon"
              :src="
                require(`../../assets/image/tyskType/${item.resourceType}.png`)
              "
              alt=""
            />
            <div
              class="circle"
              :class="{
                green: item.complete == 1,
                orange: item.complete == 2,
              }"
            >
              {{ index + 1 }}
            </div>
          </div>
          <div class="right">
            <span v-if="item.complete == 0">{{ $t('Pub_Tab_NotStart') }}</span>
            <!-- 未开始 -->
            <span v-else-if="item.complete == 1" style="color: #347f0f">
              {{ $t('enterprise.complete_time') }}：{{ dateFormat(item.completeTime) }}
              <!-- 完成时间： -->
            </span>
            <span v-else-if="item.complete == 2" style="color: #e38309">
              {{ $t('enterprise.recent_study') }}：{{ dateFormat(item.lastTime) }}
              <!-- 最近学习： -->
            </span>
            <span v-else-if="item.complete == 3"> {{ $t('enterprise.learn_state', 3) }} </span>
            <!-- 逾期未完成 -->
            <span class="pop" v-if="item.complete == 2">
              {{ item.progress }}%
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="info-foot" v-if="!loadding">
      <template v-if="infoData.complete == 1">
        <div class="end-img over"></div>
        <p>{{ $t('enterprise.have_completed_all') }}</p>
        <!-- 恭喜您任务已经全部学完！ -->
      </template>
      <template v-else-if="infoData.complete == 0">
        <div class="end-img"></div>
        <p>{{ $t('enterprise.have_not_started_learn') }}</p>
        <!-- 您还没有开始学习！ -->
      </template>
      <template v-else>
        <div class="end-img"></div>
        <p>
          {{ $t('enterprise.tip', 0) }}
          <!-- 您已完成 -->
          <i>{{ detailNum.yes }}</i>
          {{ $t('enterprise.tip', 1) }}
          <!-- 个任务，还剩 -->
          <i>{{ detailNum.all - detailNum.yes }}</i>
          {{ $t('enterprise.tip', 2) }}
          <!-- 个学习任务！ -->
        </p>
      </template>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { enterpriseMemberDetail } from "@/api/user";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";

export default {
  components: {
    OpenData,
  },
  setup() {
    const route = useRoute();

    const taskId = Number(route.query.taskId);
    const userId = Number(route.query.userId);

    const loadding = ref(true);
    const infoData = ref({});
    const member = ref({});
    const detailNum = ref({
      yes: 0,
      all: 0,
    });
    enterpriseMemberDetail(userId, taskId).then((res) => {
      let stages = res.data.stages;
      for (let i = 0; i < stages.length; i++) {
        for (let j = 0; j < stages[i].details.length; j++) {
          detailNum.value.all++;
          if (stages[i].details[j].complete == 1) {
            detailNum.value.yes++;
          }
        }
      }
      infoData.value = res.data;
      member.value = res.data.member;
      loadding.value = false;
    });

    return {
      dateFormat,
      loadding,
      infoData,
      member,
      detailNum,
    };
  },
};
</script>
<style lang="less" scoped>
@import "~ant-design-vue/lib/style/themes/default.less";
@import "~@/assets/style/common.less";
.content {
  .mixinWrap();
  padding: 32px 0 40px;
}
.info-head {
  background-color: #fafafa;
  padding: 24px;
  .mixinFlex(space-between; center);
  position: relative;
  .user {
    border-right: 1px solid #ddd;
    width: 50%;
    .mixinFlex(center; center);
    .portrait {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 38px;
    }
  }
  .task {
    width: 50%;
    .mixinFlex(center; center);
    .text {
      width: 80%;
    }
  }
  .user,
  .task {
    p {
      margin: 0;
      color: #333;
      font-size: 16px;
      line-height: 1.5;
      strong {
        font-size: 18px;
      }
      &.title {
        .mixinEllipsis(50px, 2);
      }
    }
  }
  .progress {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 80px;
    left: calc(50% - 60px);
    z-index: 10;
  }
}
.info-body {
  background-color: #fff;
  padding-top: 85px;
  line-height: 28px;
  font-size: 14px;
  .stage {
    &-title {
      font-size: 16px;
      color: #333;
      margin: 0;
      padding: 5px 0;
      text-align: center;
    }
  }
  .item {
    padding: 16px 0;
    position: relative;
    .mixinFlex(space-between);
    &::after {
      content: "";
      width: 1px;
      background-color: #e2e2e2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 1px);
    }
    .left {
      width: 50%;
      padding: 0 24px;
      position: relative;
      .mixinFlex(flex-end; center);
      .icon {
        margin-left: 10px;
        width: 22px;
        height: 22px;
      }
      .circle {
        width: 21px;
        height: 21px;
        position: absolute;
        right: -11px;
        top: calc(50% - 12px);
        border: 1px solid #333;
        border-radius: 50%;
        background-color: #fff;
        z-index: 10;
        font-size: 12px;
        color: #333;
        text-align: center;
        line-height: 19px;
        &.green {
          background-color: #347f0f;
          color: #fff;
          border-color: #347f0f;
        }
        &.orange {
          background-color: #e38309;
          color: #fff;
          border-color: #e38309;
        }
      }
    }
    .right {
      width: 50%;
      padding: 0 24px;
      .pop {
        border: 1px solid #cccccc;
        border-radius: 3px;
        width: 60px;
        height: 32px;
        margin-left: 20px;
        text-align: center;
        display: inline-block;
        position: relative;
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          left: -5px;
          top: 8px;
          border-left: 1px solid #ddd;
          border-top: 1px solid #ddd;
          background-color: #fff;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
.info-foot {
  padding-bottom: 20px;
  background-color: #fff;
  .end-img {
    margin: 0 auto;
    width: 120px;
    height: 63px;
    background-image: url("~@/assets/image/end-gray.png");
    background-repeat: no-repeat;
    background-size: cover;
    &.over {
      background-image: url("~@/assets/image/end-red.png");
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #333;
    margin: 0;
    padding: 10px 0;
    i {
      color: #347f0f;
      font-style: normal;
    }
  }
}
</style>
